@import url('https://fonts.googleapis.com/css2?family=Arima:wght@100..700&family=Londrina+Sketch&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

html {
  font-weight: 400;
  font-style: normal;
  width: 100%;
  height: 100%;
  background: #161c2b;
  background: linear-gradient(180deg,
  #0D0D0D 0%, #0D0D0D 50%, #080808 100%);
  color: #ebede9;

  body, #root {
    font-family: 'Montserrat', sans-serif;
      min-height: 100vh;
      margin: 0;
  }

  #root {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a {
    color: #6ea8fe;
  }
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  padding: 20px 40px;
  max-width: 1140px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;

  .logo {
      flex: 1 1 auto;
      display: flex;
      justify-content: left;
      font-family: "Lilita One", sans-serif;
      font-weight: 200;
      font-style: normal;
      font-size: 2.5rem;
      color: #ebede9;

      p {
          margin: 0;
      }

      .sub {
          font-size: 1.5rem;
          font-weight: normal;
          margin: 0;
      }
  }
  
  .socials{
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ebede9;
      
      a {
          transform: scale(1);
          transition: ease-in-out all 0.3s;
          
      }
      a:hover {
          transition: ease-in-out all 0.3s;
          transform: scale(1.25);
      }

      img {
          width: 48px;
          height: 48px;
          margin: 0 20px;
      }

      .x-logo img{
          width: 42px;
          height: 42px;
      }
  }
}

.section{
  width: 100%;
  max-width: 740px;
  margin: 2em;;
  margin-bottom: 0em;
  padding: 0.75em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-align: center;
}

.h-divider {
  margin: auto;
  width: 80%;
  position: relative;
}

.h-divider .shadow {
  overflow: hidden;
  height: 20px;
}

.h-divider .shadow:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px black;
}

main {
  flex-grow: 1;
  padding: 20px 40px;
  max-width: 720px;
  min-height: 500px;
  margin: 0 auto;
  

  .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 60px;
  }
  
  h1 {
      text-align: center;
      margin-top: 0.5em;
      margin-bottom: 1em;
  }
}

footer {
  text-align: center;
  opacity: 0.5;
  margin: 15px 0;
}
a, .btn-link {
  color: #006bb7;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

.content {
  padding-top: 1.1rem;
}

h1:focus {
  outline: none;
}

.valid.modified:not([type=checkbox]) {
  outline: 1px solid #26b050;
}

.invalid {
  outline: 1px solid #e50000;
}

.validation-message {
  color: #e50000;
}

.blazor-error-boundary {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNDkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIG92ZXJmbG93PSJoaWRkZW4iPjxkZWZzPjxjbGlwUGF0aCBpZD0iY2xpcDAiPjxyZWN0IHg9IjIzNSIgeT0iNTEiIHdpZHRoPSI1NiIgaGVpZ2h0PSI0OSIvPjwvY2xpcFBhdGg+PC9kZWZzPjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMzUgLTUxKSI+PHBhdGggZD0iTTI2My41MDYgNTFDMjY0LjcxNyA1MSAyNjUuODEzIDUxLjQ4MzcgMjY2LjYwNiA1Mi4yNjU4TDI2Ny4wNTIgNTIuNzk4NyAyNjcuNTM5IDUzLjYyODMgMjkwLjE4NSA5Mi4xODMxIDI5MC41NDUgOTIuNzk1IDI5MC42NTYgOTIuOTk2QzI5MC44NzcgOTMuNTEzIDI5MSA5NC4wODE1IDI5MSA5NC42NzgyIDI5MSA5Ny4wNjUxIDI4OS4wMzggOTkgMjg2LjYxNyA5OUwyNDAuMzgzIDk5QzIzNy45NjMgOTkgMjM2IDk3LjA2NTEgMjM2IDk0LjY3ODIgMjM2IDk0LjM3OTkgMjM2LjAzMSA5NC4wODg2IDIzNi4wODkgOTMuODA3MkwyMzYuMzM4IDkzLjAxNjIgMjM2Ljg1OCA5Mi4xMzE0IDI1OS40NzMgNTMuNjI5NCAyNTkuOTYxIDUyLjc5ODUgMjYwLjQwNyA1Mi4yNjU4QzI2MS4yIDUxLjQ4MzcgMjYyLjI5NiA1MSAyNjMuNTA2IDUxWk0yNjMuNTg2IDY2LjAxODNDMjYwLjczNyA2Ni4wMTgzIDI1OS4zMTMgNjcuMTI0NSAyNTkuMzEzIDY5LjMzNyAyNTkuMzEzIDY5LjYxMDIgMjU5LjMzMiA2OS44NjA4IDI1OS4zNzEgNzAuMDg4N0wyNjEuNzk1IDg0LjAxNjEgMjY1LjM4IDg0LjAxNjEgMjY3LjgyMSA2OS43NDc1QzI2Ny44NiA2OS43MzA5IDI2Ny44NzkgNjkuNTg3NyAyNjcuODc5IDY5LjMxNzkgMjY3Ljg3OSA2Ny4xMTgyIDI2Ni40NDggNjYuMDE4MyAyNjMuNTg2IDY2LjAxODNaTTI2My41NzYgODYuMDU0N0MyNjEuMDQ5IDg2LjA1NDcgMjU5Ljc4NiA4Ny4zMDA1IDI1OS43ODYgODkuNzkyMSAyNTkuNzg2IDkyLjI4MzcgMjYxLjA0OSA5My41Mjk1IDI2My41NzYgOTMuNTI5NSAyNjYuMTE2IDkzLjUyOTUgMjY3LjM4NyA5Mi4yODM3IDI2Ny4zODcgODkuNzkyMSAyNjcuMzg3IDg3LjMwMDUgMjY2LjExNiA4Ni4wNTQ3IDI2My41NzYgODYuMDU0N1oiIGZpbGw9IiNGRkU1MDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvZz48L3N2Zz4=) no-repeat 1rem/1.8rem, #b32121;
  padding: 1rem 1rem 1rem 3.7rem;
  color: white;
}

.blazor-error-boundary::after {
  content: "An error has occurred."
}

.darker-border-checkbox.form-check-input {
  border-color: #929292;
}

@keyframes moveBackground {
  0% {
      background-position: 0 0;
  }
  100% {
      background-position: 100% 100%;
  }
  
}

@keyframes moveBackground_2 {
  0% {
      background-position: 0 100%;
  }
  100% {
      background-position: 100% 0;
  }

}

.battleground{
  display: block;
  position: absolute;
  width: 100vw;
  height: 200px;
  bottom: 0;
  /*background: url("/images/battleground_back.png") repeat-x;*/
  /*background: url("${process.env.PUBLIC_URL}/images/battleground_back.png") repeat-x;*/
  opacity: 1;
}

.card {
  position: relative;
  overflow: hidden;
  width: 315px;
  transition: ease-in-out all 0.3s;
  
  .card-description {
      font-size: 1.2rem;
      text-align: center;
      opacity: 0.8;
      margin-top: 1em;
  }
}

.card:hover {
  
  transform: scale(1.05);
  transition: ease-in-out all 0.3s;
  
  .card-image {
      transition: ease-in-out all 0.3s;
  }
  
  a p {
      opacity: 1;
      transition: ease-in-out all 0.3s;
  }
}

.card a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 315px;
  height: 250px;
  text-decoration: none;
  color: inherit;
}

.card a .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.card a p {
  text-align: center;
  position: absolute;
  font-family: Arial, sans-serif;
  font-size: 3rem;
  opacity: 0.5;
  transition: ease-in-out all 0.3s;
}

.card-image {
  box-shadow: 0 0 4px #080808;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
  transition: ease-in-out all 0.3s;
}

.card-placeholder {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
}

.card-content {
  padding: 16px;
}